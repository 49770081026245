//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import {rzContainer, rzButton, rzDropdown, rzDottedLoader, rzIconDropArrow, rzTransitionExpand} from 'razlet-ui';
import MainLogo from '~/components/main-logo';
import MainContacts from '~/components/main-contacts';
import timeSrc from '~/assets/img/time.png';
import weatherSrc from '~/assets/img/weather.png';
import {lockScroll, unlockScroll} from '~/utils/lock-scroll';

export default {
  name: 'main-header',
  components: {
    rzContainer,
    rzDropdown,
    rzButton,
    rzDottedLoader,
    MainLogo,
    rzIconDropArrow,
    rzTransitionExpand,
    MainContacts,
  },
  data() {
    return {
      isMenu: false,
      activeMenuIndex: null,
      timer: null,
      currentDate: null,
      timeSrc,
      weatherSrc,
    };
  },
  computed: {
    ...mapGetters(['menu', 'weather', 'about']),
  },
  watch: {
    '$route': {
      handler() {
        this.isMenu = false;
        setTimeout(() => {
          unlockScroll();
        }, 320);
      },
    },
    deep: true,
  },
  created() {
    this.getDate();
    this.timer = setInterval(() => {
      this.getDate();
    }, 61000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getPhoneLink(phone) {
      return phone ? phone.replace(/ /g, '').replace(/-/g, '').replace(/[()]/g, '') : '';
    },
    setActiveMenuIndexByRoute() {
      this.menu.forEach((head, index) => {
        head.items.forEach(subItem => {
          if (subItem.link === this.$route.path) {
            this.activeMenuIndex = index;
          }
        });
      });
    },
    changeLocale(locale) {
      this.$local = locale;
    },
    getDate() {
      const date = new Date();
      /* const dateStr = date.toLocaleString('ru', {
        timeZone: 'Europe/Moscow',
        hour: '2-digit',
        minute: '2-digit',
        month: 'long',
        day: 'numeric',
      }); */
      const dateStr = new Intl.DateTimeFormat('ru', {
        timeZone: 'Europe/Moscow',
        hour: '2-digit',
        minute: '2-digit',
        month: 'short',
        day: 'numeric',
      }).format(date);

      this.currentDate = `${dateStr.replace(',', '')} мск`;
    },
    openMenu() {
      if (!this.isMenu) {
        this.setActiveMenuIndexByRoute();
        lockScroll();
      }
      else setTimeout(() => {
        unlockScroll();
      }, 320);
      this.isMenu = !this.isMenu;
    },
    goTo(route) {
      if (this.$route.name !== route) {
        this.$router.push({ name: route });
        this.isMenu = false;
      }
    },
    toggleMenuItem(index) {
      this.activeMenuIndex = this.activeMenuIndex === index ? -1 : index;
    },
    isMenuItemActive(index) {
      if (this.activeMenuIndex === null) return false;
      return this.activeMenuIndex === index;
    },
  },
};
